<template>
  <v-container class="text-center">
    <v-btn
      :loading="loadingWmBulkRequestFile"
      color="green"
      @click="processFile"
    >
      Procesar archivo adjuntado
    </v-btn>
  </v-container>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'CoreBulkLoad',
    props: {
      file: {
        type: File,
        default () {
          return undefined
        },
      },
    },
    computed: {
      ...mapState([
        'loadingWmBulkRequestFile',
        'loadWmBulkRequestResult',
      ]),
      fileFormData () {
        const formData = new FormData()
        formData.set('file', this.file)

        return formData
      },
    },
    watch: {
      loadWmBulkRequestResult (loadWmBulkRequestResult) {
        if (loadWmBulkRequestResult !== undefined) {
          if (loadWmBulkRequestResult.result.ok === false) {
            this.$emit('setConsoleData', 'Error: ' + loadWmBulkRequestResult.result.error.message)
          } else {
            this.setSuccess('Los datos se han cargado satisfactoriamente')
            this.$emit('setConsoleData', 'Los datos se han cargado satisfactoriamente')
          }
        }
      },
    },
    created () {
      this.$emit('disabledNext', true)
    },
    methods: {
      ...mapActions([
        'wmBulkLoadLoadFile',
        'setSuccess',
      ]),
      async processFile () {
        await this.wmBulkLoadLoadFile(this.fileFormData)
      },
    },
  }
</script>
