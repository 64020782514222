<template>
  <div>
    <v-container>
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step
            :complete="step > 1"
            color="secondary"
            step="1"
          >
            Descarga el archivo
          </v-stepper-step>
          <v-divider />
          <v-stepper-step step="2">
            Verificación del archivo
          </v-stepper-step>
          <v-divider />
          <v-stepper-step step="3">
            Carga los datos
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <download-file />
            <stepper-footer
              :prev-button-disabled="true"
              @click="step = 2"
              @back="() => undefined"
            />
          </v-stepper-content>
          <v-stepper-content step="2">
            <file-verification
              @disabledNext="state => setDisabledState('1', 'next', state)"
              @setConsoleData="msg => setConsoleData(msg)"
              @setFile="setFile"
            />
            <stepper-footer
              :next-button-disabled="getDisabledState('1', 'next')"
              :prev-button-disabled="false"
              @click="() => { step = 3; clearConsoleData() }"
              @back="() => { step = 1; clearConsoleData() }"
            />
          </v-stepper-content>
          <v-stepper-content step="3">
            <core-bulk-load
              :file="file"
              @disabledNext="state => setDisabledState('2', 'next', state)"
              @disabledPrev="state => setDisabledState('2', 'prev', state)"
              @setConsoleData="msg => setConsoleData(msg)"
            />
            <stepper-footer
              :next-button-disabled="getDisabledState('2', 'next')"
              :prev-button-disabled="getDisabledState('2', 'prev')"
              @click="() => undefined"
              @back="() => { step = 1; clearConsoleData() }"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-container>
    <console :text="consoleData" />
  </div>
</template>

<script>
  import DownloadFile from '../components/warehouses-movements-bulk-load/DownloadFile'
  import FileVerification from '../components/warehouses-movements-bulk-load/FileVerification.vue'
  import StepperFooter from '../components/warehouses-movements-bulk-load/StepperFooter.vue'
  import Console from '../components/warehouses-movements-bulk-load/Console.vue'
  import CoreBulkLoad from '../components/warehouses-movements-bulk-load/CoreBulkLoad'

  export default {
    name: 'WarehousesMovementsBulkLoad',
    components: {
      DownloadFile,
      FileVerification,
      StepperFooter,
      Console,
      CoreBulkLoad,
    },
    data () {
      return {
        step: 1,
        file: undefined,
        consoleData: '',
        disabledStepButtons: {
          1: {
            next: true,
            prev: true,
          },
          2: {
            next: false,
            prev: false,
          },
          3: {
            next: true,
            prev: true,
          },
        },
      }
    },
    methods: {
      setDisabledState (step, buttonKey, state) {
        this.disabledStepButtons[step][buttonKey] = state
      },
      getDisabledState (step, buttonKey) {
        return this.disabledStepButtons[step][buttonKey]
      },
      appendConsoleData (data, br = true) {
        if (br) {
          data = '\n' + data
        }
        this.consoleData += data
      },
      clearConsoleData () {
        this.consoleData = ''
      },
      setConsoleData (data) {
        this.clearConsoleData()
        this.appendConsoleData(data, false) // -> no br
      },
      setFile (file) {
        this.file = file
      },
    },
  }
</script>
