<template>
  <v-container>
    <input
      :id="fileTriggerId"
      type="file"
      style="display: none"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      @change="handleFileTriggerChange"
    >
    <v-row>
      <v-col
        cols="12"
        :md="attachedFile ? 6 : 12"
        class="text-center"
      >
        <v-btn
          color="secondary"
          @click="openFileTrigger"
        >
          Seleccionar archivo
        </v-btn>
      </v-col>
      <v-col
        v-if="attachedFile"
        cols="12"
        md="6"
        class="text-center"
      >
        <h2>Archivo adjunto:</h2>
        <p>{{ file.name }}</p>
      </v-col>
      <v-col
        v-if="attachedFile"
        cols="12"
        class="text-center"
      >
        <v-btn
          color="blue"
          @click="checkFile"
        >
          Verificar archivo
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { v4 as uuidv4 } from 'uuid'
  import { mapActions, mapState } from 'vuex'

  export default {
    name: 'FileVerification',
    data () {
      return {
        file: undefined,
      }
    },
    computed: {
      ...mapState([
        'checkingWmBulkRequestFile',
        'wmBulkRequestCheckingResult',
      ]),
      fileTriggerId () {
        return `${uuidv4()}-file-trigger`
      },
      fileTrigger () {
        return document.getElementById(this.fileTriggerId)
      },
      attachedFile () {
        return this.file !== undefined
      },
      formData () {
        const formData = new FormData()
        formData.set('file', this.file)

        return formData
      },
    },
    watch: {
      wmBulkRequestCheckingResult (wmBulkRequestCheckingResult) {
        if (wmBulkRequestCheckingResult !== undefined) {
          if (wmBulkRequestCheckingResult.error === true) {
            this.$emit('setConsoleData', wmBulkRequestCheckingResult.message)
            this.$emit('disabledNext', true)
          } else {
            this.$emit('setConsoleData', 'La información está correcta')
            this.$emit('disabledNext', false)
          }
        }
      },
    },
    mounted () {
      this.$emit('disabledNext', true)
    },
    methods: {
      ...mapActions([
        'wmBulkLoadCheckFile',
      ]),
      openFileTrigger () {
        this.fileTrigger.click()
      },
      handleFileTriggerChange (e) {
        const file = e.target.files[0]
        if (file) {
          this.file = file
          this.$emit('setFile', this.file)
        }
      },
      async checkFile () {
        await this.wmBulkLoadCheckFile(this.formData)
      },
    },
  }
</script>
